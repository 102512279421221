<template>
    <div>
        <div class="bkColor">
            <headTitle :title="title" :routerPath="routerPath">
            </headTitle>
            <div class="projectTitle">
                <div class="T">{{ supplierDetails.name }}</div>
                <!-- <div style="font-size:14px">单位地址: {{ supplierDetails.address }}</div> -->
            </div>
            <div class="projectBody">
                <van-field v-model="supplierDetails.address" label="单位地址" readonly autosize type="textarea" />
                <van-field v-model="supplierDetails.contacts" label="联系人" readonly />
                <van-field v-model="supplierDetails.mobile" label="联系电话" readonly />
                <van-field v-model="supplierDetails.bank" label="开户行" readonly autosize type="textarea" />
                <van-field v-model="supplierDetails.bankNumber" label="行账号" readonly />
                <van-field v-model="supplierDetails.taxNumber" label="税号" readonly />
                <van-field v-model="supplierDetails.mainProducts" label="主营业务" readonly autosize type="textarea" />
                <van-field v-model="isBusinessLicense" label="有无营业执照" readonly />

                <downloadFile :fileList="supplierDetails.fileList" :routerPath="supplierDetails">
                </downloadFile>
                <van-field v-model="isPriceList" label="有无报价单" readonly />
            </div>
        </div>
        <div class="footContent">
            <div class="footButton" @click="delCell">
                <van-icon name="delete-o" size="16px" />
                删除
            </div>
            <div class="footButton" @click="modification">
                <van-icon name="todo-list-o" size="16px" />
                修改
            </div>
        </div>
        <van-popup v-model="isModify" :style="{ width: '80%', padding: '20px', maxHeight: '80%', overflow: 'auto' }" round>

            <van-form validate-first @failed="onFailed" @submit="submitForm" ref='form'>
                <van-field v-model="queryFrom.name" label="供应商单位" placeholder="请输入"
                    :rules="[{ required: true, message: '供应商单位不能为空' }]" required />
                <van-field v-model="queryFrom.address" label="单位地址" type="textarea" autosize placeholder="请输入"
                    :rules="[{ required: true, message: '单位地址不能为空' }]" required />
                <van-field v-model="queryFrom.contacts" label="联系人" placeholder="请输入" />
                <van-field v-model="queryFrom.mobile" label="联系电话" placeholder="请输入"
                    :rules="[{ required: true, message: '联系电话不能为空' }]" required />
                <van-field v-model="queryFrom.bank" label="开户行" type="textarea" autosize placeholder="请输入"
                    :rules="[{ required: true, message: '开户行不能为空' }]" required />
                <van-field v-model="queryFrom.bankNumber" label="行账号" placeholder="请输入"
                    :rules="[{ required: true, message: '行账号不能为空' }]" required />
                <van-field v-model="queryFrom.taxNumber" label="税号" placeholder="请输入"
                    :rules="[{ required: true, message: '税号不能为空' }]" required />
                <van-field v-model="queryFrom.mainProducts" type="textarea" autosize label="主营产品" placeholder="请输入" />
                <van-field name="radio" label="有无营业执照" required>
                    <template #input>
                        <van-radio-group v-model="queryFrom.businessLicense" direction="horizontal" @change="clearFile">
                            <van-radio :name="1">有</van-radio>
                            <van-radio :name="0">无</van-radio>
                        </van-radio-group>
                    </template>
                </van-field>
                <van-field v-show="queryFrom.businessLicense == '1'" name="uploader" label="附件">
                    <template #input>
                        <van-uploader v-model="uploader" @click-preview="viewFile" accept="file" upload-icon="plus"
                            :after-read="afterRead" :before-read="beforeRead" @delete="deleteFile"
                            :preview-full-image="false" />
                    </template>
                </van-field>

                <van-field name="radio" label="有无报价单" required>
                    <template #input>
                        <van-radio-group v-model="queryFrom.priceList" direction="horizontal">
                            <van-radio :name="1">有</van-radio>
                            <van-radio :name="0">无</van-radio>
                        </van-radio-group>
                    </template>
                </van-field>
                <van-button round block type="info" native-type="submit" :disabled="isLoading" :loading="isLoading"
                    loading-text="加载中...">提交</van-button>
            </van-form>
        </van-popup>
        <div v-show="isReviewImg"
            style="position: fixed;top: 0;left:0;right:0;bottom:0;margin:auto;width: 100%;height: 100%;background: #323233;z-index: 9999;overflow: auto;"
            @click="isReviewImg = false">
            <img :src="reViewImg" alt="" style="width: 100%;position: absolute;top: 0;left: 0;bottom: 0;right: 0;" />
        </div>
    </div>
</template>

<script>
import headTitle from "../components/headTitle.vue";
import downloadFile from "../components/downloadFile.vue";
import { Field, Uploader, Button, Toast, Popup, Dialog, Icon, Radio, RadioGroup, DatetimePicker, Form } from 'vant'
import Vue from 'vue';
Vue.use(Field)
Vue.use(Uploader)
Vue.use(Button)
Vue.use(Toast)
Vue.use(Popup)
Vue.use(Dialog)
Vue.use(Icon)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(DatetimePicker);
Vue.use(Form)

import { timeChange } from '../units/units'
import { getSupplier, supplierDeltel, supplierPut } from '../api/supplier'
import { fileUpload } from '../api/api'

import { ddShare } from "../units/dd";
export default {
    name: 'supplierDetails',
    components: {
        headTitle,
        downloadFile
    },
    mounted() {
        this.id = this.$route.query.id
        this.isProject = this.$route.query.route ? true : false
        this.getSupplierDetail()
    },
    data() {
        return {
            title: '供应商详情',
            id: '',
            supplierDetails: {
            },
            queryFrom: {
                name: '',
                address: '',
                contacts: '',
                mobile: '',
                bank: '',
                bankNumber: '',
                mainProducts: '',
                businessLicense: 0,
                priceList: 0,
                taxNumber: '',
                fileList: [], //文件列表
            },
            powerList: [],//value 1总经理 2财务 3行政
            routerPath: '/supplierList',//路由跳转记录
            belong: '',
            showC: false,
            currentDate: new Date(),
            isModify: false,
            isLoading: false,
            isBusinessLicense: '',
            isPriceList: '',

            uploader: [], //附件
            uploading: false, //是否正在上传
            isReviewImg: false,//是否预览图片
            reViewImg: '',
        }
    },
    methods: {
        getSupplierDetail() {
            getSupplier(this.id).then(res => {
                this.supplierDetails = res.data.data
                this.isBusinessLicense = this.getStateText(this.supplierDetails.businessLicense)
                this.isPriceList = this.getStateText(this.supplierDetails.priceList)
                this.pageShare()
            })
        },
        getStateText(text) {
            switch (text) {
                case 0:
                    return "无"
                case 1:
                    return "有"
                default:
                    "--"
            }
        },
        getTime(time) {
            return timeChange(time)
        },

        pageShare() {
            let name = '供应商详情'
            ddShare('/supplierDetails?id=' + this.id, name)
        },
        delCell() {
            Dialog.confirm({
                message: `确定删除？`,
            }).then(() => {
                supplierDeltel(this.id).then(res => {
                    if (res.data.code == 200) {
                        Toast.success('操作成功')
                        this.$router.replace('/supplierList')
                    }
                })
            });
        },
        modification() {
            this.queryFrom = JSON.parse(JSON.stringify(this.supplierDetails))
            this.uploader = this.queryFrom.fileList
            this.isModify = true
        },
        chooseTime() {
            this.showC = false
            this.queryFrom.supplierTime = this.currentDate
                .toLocaleString()
                .split(" ")[0];
            this.queryFrom.supplierTime = this.queryFrom.supplierTime.replace(/\//g, "-");
        },
        //校验不通过
        onFailed(errorInfo) {
            Toast.fail(errorInfo.errors[0].message)
        },
        submitForm() {
            this.isLoading = true
            supplierPut(this.queryFrom).then(res => {
                if (res.data.code == 200) {
                    this.isLoading = false
                    Toast.success("提交成功");
                    this.isModify = false
                    this.getSupplierDetail()
                }
            }).catch(() => {
                this.isLoading = false
            })
        },
        beforeRead(file) {
            if (file.type == 'application/msword') {
                Toast('请将.doc类型文件另存为改成.docx类型的文件后再进行上传（请注意:手动改后缀可能文件类型并没有变化）')
                return
            }
            //上传之前校验
            if (file.size > 30 * 1024 * 1024) {
                Toast("只允许上传30M的文件");
                return false;
            }
            return true;
        },
        async afterRead(file) {
            //文件读取完成后的回调函数
            file.status = "uploading";
            file.message = "上传中...";
            this.uploading = true;
            let fd = new FormData();
            fd.append("file", file.file);
            await fileUpload(fd)
                .then((res) => {
                    if (res.data.code == 200) {
                        let obj = {
                            fileName: res.data.originalName,
                            url: res.data.url,
                        };

                        this.uploader[this.uploader.length - 1].url = res.data.url; //给返回的文件添加url属性
                        this.uploader[this.uploader.length - 1].fileName =
                            res.data.originalName;
                        console.log(this.queryFrom)
                        if (this.queryFrom.fileList == null) {
                            this.queryFrom.fileList = []
                        }
                        this.queryFrom.fileList.push(obj);
                        file.status = "";
                        file.message = ""; //上传状态
                        this.uploading = false;
                    } else {
                        file.status = "failed";
                        file.message = "上传失败";
                        this.uploading = false;
                    }
                }).catch((err) => {
                    if (err) {
                        file.status = "failed";
                        file.message = "上传失败";
                        this.uploading = false;
                    }
                });
        },
        deleteFile(file) {
            this.queryFrom.fileList = this.queryFrom.fileList.filter((it) => {
                return it.url !== file.url;
            });
        },
        viewFile(file) {
            if (
                file.url.indexOf(".jpg") !== -1 ||
                file.url.indexOf(".jpeg") !== -1 ||
                file.url.indexOf(".png") !== -1
            ) {
                this.isReviewImg = true
                this.reViewImg = file.url
                return;
            }
            this.$router.push({
                path: "/preview",
                query: { url: file.url, routerPath: "/supplierDetails" },
            });
        },

        clearFile() {
            this.queryFrom.fileList = []
            this.uploader = []
        }
    }
}
</script>

<style lang="less" scoped>
.bkColor {
    width: 100vw;
    background: #f6f6f6;
    padding-bottom: 9vh;
    position: relative;
    overflow: auto;
}

.projectTitle {
    width: 100vw;
    background: #fff;
    text-align: left;
    padding: 10px;
    box-sizing: border-box;
    box-sizing: border-box;
    margin-bottom: 2vh;
    position: relative;

    .T {
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 1.5vh
    }

    .D {
        color: #F0A239;
        font-size: 1em;
    }

    .I {
        width: 40px;
        height: 40px;
        position: absolute;
        top: 50%;
        right: 70px;
    }
}

.projectBody {
    background: #fff;
}

/deep/ .van-cell {
    flex-wrap: wrap;
    // margin-bottom: 1vh;
}

/deep/ .van-cell__title {
    color: #797979;
}

/deep/ .van-field__label,
.van-cell__title {
    width: 100%;

}

/deep/ .van-cell::after {
    border-bottom: none;
}

.fileList {
    width: 100%;
    padding: 10px 16px;
    box-sizing: border-box;
    font-size: 14px;
    color: #797979;

    .title {
        text-align: left;
    }

    .fileImg {
        width: 80px;
        height: 80px;
    }

    .fileName {
        width: 110px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.reviewedList {
    padding: 10px 16px;
    background: #fff;
    overflow: auto;
    position: relative;

    .A {
        top: 0px !important;
    }

    .T {
        top: 0px !important
    }

    .B {
        top: 45px !important;
    }

    .U {
        top: 20px !important
    }

    .F {
        height: 70px !important;
        min-height: 90px;
        margin: 0 !important;
    }
}

.title {
    font-size: 1rem;
    font-weight: 600;
    text-align: left;
    margin-bottom: 2vh;
}

.reviewed {
    position: relative;
    // height: 160px;
    min-height: 100px;
    // margin-bottom: 120px;
    padding: 10px 20px;
    box-sizing: border-box;
    // overflow: auto;

    .avatar {
        width: 40px;
        height: 40px;
        border-radius: 5px;
        position: absolute;
        left: 20px;
    }

    .status {
        font-size: 13px;
        position: absolute;
        top: 10px;
        left: 75px;
    }

    .time {
        font-size: 13px;
        position: absolute;
        top: 10px;
        right: 20px;
        color: #B4B4B4;
    }

    .userName {
        position: absolute;
        top: 30px;
        left: 75px;
        font-size: 13px;
        font-weight: 600;
    }

    .remark {
        width: 60vw;
        // position: absolute;
        // left: 40px;
        max-height: 55px;
        font-size: 13px;
        background: #f6f6f6;
        text-align: left;
        padding: 5px;
        line-height: normal;
        word-wrap: break-word;
        overflow: auto;
    }

    .broder {
        width: 70vw;
        min-height: 50px;
        position: absolute;
        top: 55px;
        left: 45px;
        border-left: 6px solid #E4E4E4;
        padding-left: 25px
    }
}



.reviewed:nth-last-of-type(1) .broder {
    border: unset;
}

.projectFoot {
    margin-top: 2vh;
    width: 100vw;
    background: #fff;
    padding: 20px 0px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
}


/deep/ .van-goods-action {
    background: #f6f6f6;
}

/deep/ .van-goods-action-icon {
    min-width: 18%;
    background: #f6f6f6;
    font-weight: 600;
}


/deep/ .van-button {
    border-radius: 6px;
}

.footContent {
    width: 100vw;
    height: 9vh;
    padding: 0 10px;
    box-sizing: border-box;
    position: fixed;
    // top: 0;
    // right: 0;
    bottom: 0;
    left: 0;
    background: #f6f6f6;
    display: flex;
    align-items: center;
}

.footButton {
    width: 60px;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}
</style>